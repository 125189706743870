.carouselWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 20pc; */
}

.sliderContainer {
  >div {
    padding-top: 20px;
  }
}

.separatingLine {
  height: 1px;
  width: 15pc;
  border-top: 1px solid var(--basicGrey);
  margin: 0 auto;
}

.title {
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;

  @media(max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.listingWrapper {
  padding-right: 10px;
}

.searchButton {
  @media (min-width: 768px) {
    width: 20pc;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    margin: 0 auto;
  }

  margin-top: 3pc;
}