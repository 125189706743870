@import '../../../../styles/customMediaQueries.css';

.wrapper {
  @media (max-width: 1025px) {
    margin-top: 40px;
  }
}

.box1 {
  background-color: #F8F8F8;
  padding: 30px;
  border: 1px solid var(--marketplaceColor);
  border-radius: 15px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.box1Title {
  color: var(--matterColorDark);
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.box1Text {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
}

.container {
  display: grid;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }
}

.box2 {
  background-color: #F8F8F8;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 12px;
  border-radius: 15px;
  padding: 30px;

  @media (--viewportMedium) {
    gap: 32px;
    padding: 30px;
    margin: 42px 0;
  }
}

.box2Text {
  color: var(--matterColorDark);
  font-weight: 800;
  font-size: 14px;
  line-height: 22px;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 18px;
  color: var(--matterColorDark);
  padding: 5px 16px;

  &:hover {
    background-color: var(--marketplaceColor);
    border-color: var(--matterColorDark);
    transition: var(--transitionStyleButton);
    color: var(--matterColorLight);
    text-decoration: none;
  }
}